import { useTranslation } from 'react-i18next';

import arcFullPurple from "../img/illustrations/arc_full_purple.png";
import diamond from '../img/illustrations/diamond.png';
import arcFullBlue from '../img/illustrations/arc_full_blue.png';
import arcFullGreen from '../img/illustrations/arc_full_green.png';
import arcPurple from '../img/illustrations/arc_purple.png';
import logoPurple from '../img/logos/logo-purple.png';
import infrastructures from '../img/illustrations/infrastructures.png';
import tokenomics from '../img/illustrations/tokenomics.png';

// Finance 

import arcBlue from '../img/illustrations/arc_blue.png';
import logoBlueAvailabs from '../img/logos/logo-availabs-blue.png';
import invest from '../img/illustrations/invest.png';
import tokenisation from '../img/illustrations/tokenisation.png';
import neobanking from '../img/illustrations/neobanking.png';

// Incubation

import arcGreen from '../img/illustrations/arc_green.png';
import logoGreenAvailabs from '../img/logos/logo-availabs-green.png';
import mentorat from '../img/illustrations/mentorat.png';
import rd from '../img/illustrations/rd.png';
import gotomarket from '../img/illustrations/gotomarket.png';

// Projects

import fleeshBg from "../img/projects/fleesh.png";
import medicheckBg from '../img/projects/medicheck.png';
import metalskinBg from "../img/projects/metalskin.jpg";

// Team

import jpImg from '../img/equipe/jp.png';
import gilImg from '../img/equipe/gil.png';
import victorImg from '../img/equipe/victor.png';
import lucasImg from '../img/equipe/lucas.png';
import erwanImg from '../img/equipe/erwan.png';
import danielImg from '../img/equipe/daniel.png';
import kaderImg from '../img/equipe/kader.png';

import linkedinIcon from "../img/icons/linkedin.png"
import githubIcon from "../img/icons/github.png"
import youtubeIcon from "../img/icons/youtube.png"
import embleme from "../img/illustrations/embleme.png"
import worldmap from "../img/illustrations/worldmap.png"

import { Navbar } from '../components/navbar';

export const Home = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Navbar />
        <section id='home' className="first-section d-flex align-items-center">
          <div className="container-fluid">
            <div className="row justify-content-center text-center">
              <div className="col-lg-6 col-md-8 col-sm-10">
                <h5 className="multicolor">Unlock Potential</h5>
                <h1 className="white mb-20">Déchaînez la puissance du Web3 et de la blockchain</h1>
                <p className="subtitle mb-30">
                  Transformez votre vision avec la force de la blockchain.
                </p>
                <div className="button-group mb-50">
                  <a href="https://tally.so/r/w7KKbP" target="_blank" className="btn-multi">Prendre contact</a>
                </div>
              </div>
            </div>
          </div></section>
        {/*HERO*/}
        <section className="section-150 d-flex align-items-center" style={{position: 'relative', backgroundColor: 'transparent'}}>
        <div className="section-container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12 text-start">
              {/* Première image avec un z-index plus bas pour qu'elle apparaisse en dessous du texte */}
              <img className="img-fluid" src={arcFullPurple} style={{position: 'absolute', top: '90%', left: '10%', transform: 'translate(-50%, -50%)', width: '10%', zIndex: 100}} alt="" data-aos-anchor-placement="top-bottom" data-aos="fade-in" data-aos-easing="linear" data-aos-delay={500} />
              {/* Texte avec un z-index intermédiaire pour qu'il apparaisse au-dessus de la première image mais en dessous des deux autres images */}
              <h3 style={{position: 'relative', zIndex: 100}} className=" mb-20" data-aos-anchor-placement="top-bottom" data-aos="fade-in" data-aos-easing="linear" data-aos-delay={300}>Révolution technologique et innovation.
                Transformer, financer et incuber l'Avenir.
              </h3>
              {/* Deux autres images avec le z-index le plus élevé pour qu'elles apparaissent au-dessus du texte */}
              <img className="img-fluid" src={arcFullBlue} style={{position: 'absolute', top: '10%', left: '40%', transform: 'translate(-50%, -50%)', width: '10%', zIndex: 3}} alt="" data-aos-anchor-placement="top-bottom" data-aos="fade-in" data-aos-easing="linear" data-aos-delay={400} />
              <img className="img-fluid" src={arcFullGreen} style={{position: 'absolute', top: '70%', left: '80%', transform: 'translate(-50%, -50%)', width: '20%', zIndex: 3}} alt="" data-aos-anchor-placement="top-bottom" data-aos="fade-in" data-aos-easing="linear" data-aos-delay={600} />
            </div>
          </div>
        </div>
      </section>
      {/*solution*/}
      <section id="solutions" className=" section-120 d-flex align-items-center" style={{background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 32%, rgba(113,39,255,0.3) 47%, rgba(68,24,154,1) 64%, rgba(0,0,0,1) 100%)'}}>
        <div className="section-container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-6 offset-lg-3 text-center">
              <img src={arcPurple} style={{marginBottom: '-70px'}} className="img-fluid" alt="" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={100} />
              <span className="span-solution" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={200}>Solutions</span><br />
              <img className="availabs-section-logo mb-20" src={logoPurple} alt="" data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={200} />
              <h2 className="purple-gradient mb-20" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={300}>Solutions Blockchain <br />
                Innovantes et Personnalisées
              </h2>
              <p className="subtitle mb-30" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={400}>
                Availabs Solutions se positionne à l'avant-garde de la technologie blockchain, offrant des solutions sur mesure qui révolutionnent votre entreprise. Notre équipe d'experts en blockchain développe et intègre des technologies innovantes adaptées à vos processus uniques
              </p>
            <div className='mb-5'>
            <a href="https://tally.so/r/w7KKbP" className="btn-purple-section" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={500}>Prendre contact</a>
            </div>

            </div>
          </div>
          <div className="row gx-4">
            <div className="col-md-6 mb-20">
              <div className="section-card-exp text-center " data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={600}>
                <div className="section-card-exp-container">
                  <h4 className="section-card-exp-title">Infrastructures</h4>
                  <p className="section-card-exp-subtitle mb-30">
                    Gestion et l'optimisation d'infrastructures Web3, propulsant votre projet dans l'ère du numérique décentralisé.
                  </p>
                </div>
                <img className="img-fluid section-card-exp-img" src={infrastructures} alt="" />
              </div>
            </div>
            <div className="col-md-6 mb-20">
              <div className="section-card-exp text-center " data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={700}>
                <div className="section-card-exp-container">
                  <h4 className="section-card-exp-title">Marketing</h4>
                  <p className="subtitle mb-30">
                    Stratégies de marketing Web3 sur mesure, amplifiant votre visibilité et engagement dans l'univers numérique décentralisé.
                  </p>
                </div>
                <img className="img-fluid section-card-exp-img" src={diamond} alt="" />
              </div>
            </div>
            <div className="col-md-6 mb-20">
              <div className="section-card-exp text-center mb-30" data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={800}>
                <div className="section-card-exp-container">
                  <h4 className="section-card-exp-title">Intelligence artificielle</h4>
                  <p className="section-card-exp-subtitle mb-30">
                    Intégration d'intelligence artificielle pour révolutionner les interactions et services, propulsant l'innovation à son apogée.
                  </p>
                </div>
                <img className="img-fluid section-card-exp-img" src={diamond} alt="" />
              </div>
            </div>
            <div className="col-md-6 mb-20">
              <div className="section-card-exp text-center mb-30" data-aos="fade-in" data-aos-easing="linear" data-aos-anchor-placement="top-bottom" data-aos-delay={900}>
                <div className="section-card-exp-container">
                  <h4 className="section-card-exp-title">Tokenomics</h4>
                  <p className="section-card-exp-subtitle mb-30">
                    Conception et optimisation de Tokenomics, catalysant la valeur et l'adoption de votre projet dans l'écosystème Web3.
                  </p>
                </div>
                <img className="img-fluid section-card-exp-img" src={tokenomics} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*solution*/}


            {/*incubation*/}
            <section className=" section-120 d-flex align-items-center" id="green-glow-section" style={{background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 32%, rgba(47,126,183,1) 47%, rgba(26,90,136,1) 64%, rgba(0,0,0,1) 100%)'}}>
        <div className="section-container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-6 offset-lg-3 text-center">
              <img src={arcGreen} style={{marginBottom: '-50px'}} className="img-fluid" alt="" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={100} />
              <span className="span-solution">Incubation</span><br />
              <img className="availabs-section-logo mb-20" src={logoGreenAvailabs} alt="" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={200} />
              <h2 className="green-gradient mb-20" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={300}>Incubation et accompagnement
                d’entreprises novatrices
              </h2>
              <p className="subtitle mb-30" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={400}>
                Nous accompagnons de jeunes entreprises technologiques prometteuses
                à donner vie à leur vision au travers de notre réseau et de notre expertise
              </p>
              <div className='mb-5'>
              <a href="https://tally.so/r/w7KKbP" target='_blank' className="btn-green-section" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={500}>Parler de mon projet</a>
              </div>

            </div>
          </div>
          <div className="row gx-4">
            <div className="col-md-6 mb-20">
              <div className="section-green-card-exp text-center " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={600}>
                <div className="section-card-exp-container">
                  <h4 className="section-card-exp-title">Mentorat</h4>
                  <p className="section-card-exp-subtitle mb-30">
                    Élaboration de stratégies marketing spécifiques à la blockchain et construction
                  </p>
                </div>
                <img className="img-fluid section-green-card-exp-img" src={mentorat} alt="" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={700} />
              </div>
            </div>
            <div className="col-md-6 mb-20">
              <div className="section-green-card-exp text-center " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={800}>
                <div className="section-card-exp-container">
                  <h4 className="section-card-exp-title">Go to Market</h4>
                  <p className="section-card-exp-subtitle mb-30">
                    Accompagnement stratégique pour le lancement de votre entreprise technologique sur le marché.
                  </p>
                </div>
                <img className="img-fluid section-green-card-exp-img" src={rd} alt="" />
              </div>
            </div>
            <div className="col-md-6  mb-20">
              <div className="section-green-card-exp text-center " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={900}>
                <div className="section-card-exp-container">
                  <h4 className="section-card-exp-title">Recherche et Développement</h4>
                  <p className="section-card-exp-subtitle mb-30">
                    Veille permanente sur les derniers innovations  technologiques pour renforcer vos infrastructures
                  </p>
                </div>
                <img className="img-fluid section-green-card-exp-img" src={rd} alt="" />
              </div>
            </div>
            <div className="col-md-6 mb-20">
              <div className="section-green-card-exp text-center " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={1000}>
                <div className="section-card-exp-container">
                  <h4 className="section-card-exp-title">Conformité</h4>
                  <p className="section-card-exp-subtitle mb-30">
                    Assurez la conformité réglementaire de votre entreprise grâce à notre expertise
                  </p>
                </div>
                <img className="img-fluid section-green-card-exp-img" src={gotomarket} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*incubation*/}
      {/*PROJECTS*/}
      <section id="projects" className="section-projects">
        <div className="section-container">
          <div className="row">
            <div className="col-md-8  col-lg-6  col-sm-12 align-items-center text-start mb-50">
              <h2 className="white mb-20" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={100}>Availabs, la pierre angulaire de chaque projet</h2>
              <p className="subtitle mb-30" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={200}>
                Chez Availabs, chaque projet est plus qu'une simple mission. C'est un témoignage de notre engagement à fusionner innovation et excellence. Ensemble, nous transformons vos visions en réalités tangibles, en plaçant toujours vos aspirations au cœur de nos réalisations
              </p>
              <div className='mb-5'>
              <a href="https://tally.so/r/w7KKbP" className="btn-multi " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={300}>Prendre contact</a>
              </div>
            </div>
          </div>
          <div className="row mb-50">
            <div className="col-md-6" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={500}>
              <a className="a-project" href="#">
              </a><div className="card-project text-start" style={{backgroundImage: `url(${fleeshBg})`}}><a className="a-project" href="#">
                  <span className="span-solution" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={200}>Incubation</span><br />
                  <h4 className="white">Fleesh</h4>
                  <p className="subtitle mb-30">L'intelligence artificielle au service de votre transformation</p>
                </a><a href="https://fleesh.app/" target="_blank" className="btn-transparent">En savoir plus</a>
              </div>
            </div>
            <div className="col-md-6">
              <a className="a-project no-click" href="#">
              </a><div className="card-project text-start" style={{backgroundImage: `url(${metalskinBg})`}} data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={700}><a className="a-project no-click" href="#">
                  <span className="span-solution" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={200}>Solutions</span><br />
                  <h4>Metalskin</h4>
                  <p className="subtitle mb-30">Quand la barre du métro devient l'endroit le plus sain du monde</p>
                </a><a href="http://metalskin.eu" target='_blank' className="btn-transparent">En savoir plus</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*PROJECTS*/}

        {/*TEAM*/}
        <section id="team" className="section-team">
        <div className="section-container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 text-center mb-50">
              <img src={embleme} className="embleme mb-30" alt="" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={100} />
              <h2 className="mb-20 white" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={200}>Rencontrez l'équipe</h2>
              <p className="subtitle white-50" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={300}>Découvrez l'équipe d'Availabs. Chacun avec son expertise unique et son parcours singulier. Cette fusion de talents donne vie à notre Labs, où les visions deviennent réalité.</p>
            </div>
          </div>
          <div className="row">
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-6  text-center" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={450}>
              <div className="team-card">
                <img className="team-card-img" src={lucasImg} alt="" />
                <h6 className="team-title">
                  Lucas</h6>
                  <p className="p-project">Chief Executive Officer</p>
                <a target="_blank" href="https://www.linkedin.com/in/lucas-podevin/"><img className="linkedin-icon" src={linkedinIcon} alt="" /></a>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-6  text-center" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={450}>
              <div className="team-card">
                <img className="team-card-img" src={gilImg} alt="" />
                <h6 className="team-title">
                  Gil</h6>
                <p className="p-project">Advisor</p>
                <a target="_blank" href="https://www.linkedin.com/in/gil-leonard-639210b4/"><img className="linkedin-icon" src={linkedinIcon} alt="" /></a>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-4 text-center" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={500}>
              <div className="team-card">
                <img className="team-card-img" src={victorImg} alt="" />
                <h6 className="team-title">
                  Victor</h6>
                <p className="p-project">Chief Digital Officer</p>
                <a target="_blank" href="https://www.linkedin.com/in/victor-c-bb7aa9202/"><img className="linkedin-icon" src={linkedinIcon}alt="" /></a>
              </div>
            </div>
 
            <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-4 text-center" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={600}>
              <div className="team-card">
                <img className="team-card-img" src={erwanImg} alt="" />
                <h6 className="team-title">
                  Erwan</h6>
                <p className="p-project">Chief Technology Officer</p>
                <a target="_blank" href="https://github.com/ether-wan"><img className="linkedin-icon" src={githubIcon} alt="" /></a>
                <a href="#"><img className="linkedin-icon" src={linkedinIcon} alt="" /></a>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-4  text-center" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={650}>
              <div className="team-card">
                <img className="team-card-img" src={danielImg}alt="" />
                <h6 className="team-title">
                  Daniel</h6>
                <p className="p-project">Chief Design Officer</p>
                <a href="#"><img className="linkedin-icon" src={linkedinIcon} alt="" /></a>
              </div>
            </div>
         
            <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xl-4  text-center" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={750}>
              <div className="team-card">
                <img className="team-card-img" src={kaderImg} alt="" />
                <h6 className="team-title">
                  Kader</h6>
                <p className="p-project">Business Developper</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*TEAM*/}

            {/*Numbers*/}
            <section className=" section-120 d-flex align-items-center" id="blue-glow-section">
        <div className="section-container">
          <div className="row">
            <div className="col-lg-12 col-sm-12  col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-6 offset-lg-3 text-center">
              <h2 className="white mb-20" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={100}>Une équipe
                à l’expérience florissante
              </h2>
              <p className="subtitle mb-30" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={200}>
                Découvrez l'équipe d'Availabs. Chacun avec son expertise unique et son parcours singulier. C'est cette fusion de talents qui donne vie à notre Labs, où les visions deviennent réalité.
              </p>
              <div className='mb-5'>
              <a href="https://tally.so/r/w7KKbP" target='_blank' className="btn-multi" data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={300}>Parler de mon projet</a>
              </div>


            </div>
          </div>   
          <div className="row gx-2">
            <div className="col-md-4 " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={400}>
              <div className="numbers-card-purple">
                <h5 className="title-numbers">14</h5>
                <p className="h4 white">Projets à son actif</p>
              </div>
            </div>
            <div className="col-md-4 " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={500}>
              <div className="numbers-card-green">
                <h5 className="title-numbers">200k+</h5>
                <p className="h4 white">D'utilisateurs</p>
              </div>
            </div>
            <div className="col-md-4 " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={600}>
              <div className="numbers-card-blue">
                <h5 className="title-numbers">1.5M$</h5>
                <p className="h4 white">de fonds levés</p>
              </div>
            </div>
            <div className="col-md-4 " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={700}>
              <div className="numbers-card-green">
                <h5 className="title-numbers">34</h5>
                <p className="h4 white">Collaborateurs</p>
              </div>
            </div>
            <div className="col-md-4 " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={800}>
              <div className="numbers-card-blue">
                <h5 className="title-numbers">10M+</h5>
                <p className="h4 white">Abonnés</p>
              </div>
            </div>
            <div className="col-md-4 " data-aos="fade-in" data-aos-anchor-placement="top-bottom" data-aos-easing="linear" data-aos-delay={900}>
              <div className="numbers-card-purple">
                <h5 className="title-numbers">1000+</h5>
                <p className="h4 white">Tasses de café</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*numbers*/}
      
      <section className="section-120 align-items-center">
        <div className="section-container">
          <div className="row justify-content-center">
            <div className="col-md-10 team-card" style={{padding: '50px 50px 0 50px', borderRadius: '30px'}}>
              <h4 className=" white mb-50">
                Chaque innovation commence par une conversation. Ensemble avançons pour façonner un avenir inspirant.
              </h4>
              <div className='mb-3'>
              <a href="https://tally.so/r/w7KKbP" target='_blank' className="btn-multi">Parler de mon projet</a>
              </div>
              
              <img src={worldmap} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
}